import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Auth from "./components/Auth";
import About from "./components/About";
import Report from "./components/Report";
import Feedback from "./components/Feedback";
import Addquestion from "./components/Addquestion";
import Pytraining from "./components/Pytraining";
import QuestionDetail from "./components/QuestionDetail";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <BrowserRouter>
          <Routes>  
          <Route path="/python" element={<App />} />
          <Route path="/login" element={<Auth />} />
          <Route path="/about" element={<About />} />
          <Route path="/report" element={<Report />} />
          <Route path="/feedback" element={<Feedback />} />
          //<Route path="/python/addquestion" element={<Addquestion />} />
          <Route path="/training" element={<Pytraining />} />
          <Route path="/python/:primary_tag/:id" element={<QuestionDetail />} />
          <Route path="/python/:primaryTag/:randomId/report" element={<Report />} />
          </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
