import React, { useState } from 'react';
import axios from 'axios';
import './all.css';

const NotifyForm = ({ onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    address: '',
    trainingType: ''
  });
  const [otp, setOtp] = useState(''); // Store OTP received from backend
  const [enteredOtp, setEnteredOtp] = useState(''); // Store OTP entered by user
  const [otpSent, setOtpSent] = useState(false); // Track if OTP has been sent

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Send form data to backend to generate OTP
    axios.post('http://pm.9faqs.com/api/send-otp', formData)
      .then((response) => {
        setOtp(response.data.otp); // Save OTP for verification
        setOtpSent(true); // Set OTP sent status
        alert('OTP has been sent to your email!');
      })
      .catch((error) => {
        console.error('Error sending OTP:', error);
        alert('Failed to send OTP. Please check your email and try again.');
      });
  };

  const handleOtpValidation = () => {
    if (enteredOtp === otp) {
      alert('OTP verified successfully!');
      onClose(); // Close the form on successful verification
    } else {
      alert('Invalid OTP. Please try again.');
    }
  };

  return (
    <div className="notify-form-container">
      <div className="notify-form">
        <h2>Notify Me</h2>
        {!otpSent ? (
          <form onSubmit={handleSubmit}>
            <label>
              Name:
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              Email:
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              Mobile Number:
              <input
                type="text"
                name="mobile"
                value={formData.mobile}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              Address:
              <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              Training Type:
              <select
                name="trainingType"
                value={formData.trainingType}
                onChange={handleChange}
                required
              >
                <option value="">Select Training Type</option>
                <option value="basic">Basic</option>
                <option value="advanced">Advanced</option>
                <option value="expert">Expert</option>
                <option value="specialized">Specialized</option>
              </select>
            </label>
            <button type="submit">Submit</button>
            <button type="button" onClick={onClose}>Cancel</button>
          </form>
        ) : (
          <div>
            <label>
              Enter OTP:
              <input
                type="text"
                value={enteredOtp}
                onChange={(e) => setEnteredOtp(e.target.value)}
                required
              />
            </label>
            <button onClick={handleOtpValidation}>Verify OTP</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default NotifyForm;

